/* Voucher Input Field */
.input-outer1 {
  position: relative;
  margin: 0px 0px;
}
form.form-outer input[type="text"],
form.form-outer input[type="email"],
form.form-outer input[type="tel"],
form.form-outer select {
  background: hsla(0, 0%, 100%, 0);
  width: 100%;
  margin-bottom: 10px;
  outline: none;
  padding-left: 20px;
  border-radius: 30px;
  height: 46px;
  border: 1px solid #1e191a;
}

form.form-outer input[type="text"]::placeholder {
  color: #313131;
  font-weight: 600;
}

form.form-outer input[type="text"]:-ms-input-placeholder {
  color: #313131;
  font-weight: 600;
}

form.form-outer input[type="text"]::-ms-input-placeholder {
  color: #313131;
  font-weight: 600;
}

.errorMsg {
  color: #e00d0d !important;
  border-radius: 30px;
  padding: 0px 0px !important;
  text-transform: none;
  font-weight: 400;
}

input,
submit,
button {
  box-shadow: none !important;
}

/* CheckBox Filed */
.img-bg input#myCheck {
  display: inline-block;
  width: auto;
}
.img-bg form.form-outer .removing label {
  font-weight: 700;
}
.img-bg label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 500;
}

/* Survey Input Fields */
.img-bg form.form-outer.register label {
  text-transform: initial !important;
  font-weight: 400;
  font-size: 15px;
}
.img-bg input[type="checkbox"],
.img-bg input[type="radio"] {
  margin: 4px 6px 0 !important;
  margin-top: 1px \9;
  line-height: normal;
}
.img-bg form.form-outer.register label {
  text-transform: initial !important;
  font-weight: 400;
  font-size: 15px;
}
.img-bg form.form-outer.register label {
  text-transform: initial !important;
  font-weight: 400;
  font-size: 15px;
}
.img-bg label.inline {
  display: inline;
  width: auto !important;
}
.img-bg form.form-outer label {
  width: 100%;
  text-transform: inherit;
}
.img-bg input#phone {
  background: #ffffff00;
  width: 100%;
  margin-bottom: 10px;
  outline: none;
  padding-left: 20px;
  border-radius: 30px;
  height: 46px;
  border: 1px solid #1e191a;
}
.img-bg input::-webkit-outer-spin-button,
.img-bg input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.img-bg input[type="number"] {
  -moz-appearance: textfield;
}
.img-bg input#phonenumber {
  /* background: #e8e8e8; */
  background: transparent;

  width: 100%;
  margin-bottom: 10px;
  outline: none;
  padding-left: 20px;
  border-radius: 30px;
  height: 46px;
  border: 1px solid #1e191a;
}
.img-bg input#phonenumber2 {
  /* background: #e8e8e8; */
  background: transparent;

  width: 100%;
  margin-bottom: 10px;
  outline: none;
  padding-left: 20px;
  border-radius: 30px;
  height: 46px;
  border: 1px solid #1e191a;
}
.img-bg input#phonenumber3 {
  background: transparent;

  /* background: #ffffff00; */
  width: 100%;
  margin-bottom: 10px;
  outline: none;
  padding-left: 20px;
  border-radius: 30px;
  height: 46px;
  border: 1px solid #1e191a;
}
