.img-bg ul.points li {
  list-style: none;
  font-size: 15px;
  color: #313131 !important;
  padding: 17px 0px;
  font-weight: 400;
  border-bottom: 1px solid #e4e4e4;
}
.img-bg ul.points img {
  width: 55px;
}
.img-bg .white-box h2 {
  color: #1e191a;
  font-size: 17px;
  font-weight: 600;
}
.img-bg ul.points {
  padding-left: 0px;
  width: 100%;
}
.img-bg a {
  color: #000 !important;
  text-decoration: none !important;
  /* font-weight: 500; */
}
.img-bg a:hover {
  text-decoration: underline !important;
}
.img-bg ul.points li a {
  color: #f68d2d !important;
  text-decoration: none !important;
  /* font-weight: 500; */
}
.img-bg ul.points li a:hover {
  color: #1e191a !important;
  /* font-weight: 500; */
}
.img-bg ul.points li:last-child {
  border-bottom: 0px;
}

@media (min-width: 992px) {
  .img-bg .col-md-11 {
    width: 91.66666667% !important;
  }
}

@media (min-width: 768px) {
  .img-bg .col-md-11 {
    flex: 0 0 auto;
    width: -webkit-fill-available;
  }
}
